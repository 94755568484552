import React, { useState } from 'react';
import T from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import GatsbyImg from 'gatsby-image';
import { format, parseISO } from 'date-fns';
import { tint } from 'polished';

import {
  themeVal,
  glsp,
  visuallyHidden,
  listReset,
  stylizeFunction
} from '@devseed-ui/theme-provider';

const _tint = stylizeFunction(tint);

import dsLogo from '../media/layout/logo.png';

const PostAuthorFigure = styled.figure`
  border-radius: ${themeVal('shape.rounded')};
  overflow: hidden;
  width: ${glsp(2.5)};
  height: ${glsp(2.5)};

  img {
    width: 100%;
    height: auto;
  }

  * {
    vertical-align: top;
    width: inherit;
    height: inherit;
  }
`;

export const PostAuthor = styled.address`
  position: static;
  font-size: 0.75rem;
  line-height: 1rem;
  font-variation-settings: 'wdth' 96, 'wght' 512;
  text-transform: uppercase;
  font-style: normal;

  ${PostAuthorFigure} {
    grid-row: 1 / span 2;
    grid-column: 1;
  }

  strong {
    margin: 0;
    font-weight: inherit;
    grid-row: 1;
    display: block;
  }

  time {
    grid-row: 2;
    opacity: 0.64;
    display: block;
  }

  span {
    ${visuallyHidden()}
  }

  .expertise {
    grid-row: 2;
    opacity: 0.64;
    display: block;
  }

  a,
  a:visited,
  a:not([class]),
  a:not([class]):visited {
    color: inherit;
  }
`;

const PostAuthorInner = styled(Link)`
  display: inline-grid;
  grid-gap: ${glsp(0, 1)};
  grid-auto-columns: auto 1fr;
  align-items: end;
  position: relative;
  z-index: 4;
  pointer-events: auto;
  vertical-align: top;

  &,
  &:visited,
  &:not([class]):visited {
    color: inherit;
  }
`;

// Single author, linked
function AuthorAvatarBase(props) {
  const { url, name, image, children } = props;

  // If there's no url, make the PostAuthorInner a paragraph.

  if (!name) {
    return (
      <PostAuthor>
        <PostAuthorInner as={!url ? 'p' : undefined} to={url ? '/' : undefined}>
          <span>By</span>
          <PostAuthorFigure>
            <img
              className='avatar-img'
              src={dsLogo}
              alt='Development Seed logo'
            />
          </PostAuthorFigure>
          <strong>Development Seed</strong>
          {children}
        </PostAuthorInner>
      </PostAuthor>
    );
  }

  return (
    <PostAuthor>
      <PostAuthorInner as={!url ? 'p' : undefined} to={url || undefined}>
        <span>By</span>
        <PostAuthorFigure>
          <GatsbyImg
            className='author-img'
            fixed={image}
            alt={`Avatar of ${name}`}
          />
        </PostAuthorFigure>
        <strong>{name}</strong>
        {children}
      </PostAuthorInner>
    </PostAuthor>
  );
}

AuthorAvatarBase.propTypes = {
  url: T.string,
  name: T.string,
  image: T.object,
  children: T.node
};

export function AuthorAvatar(props) {
  const { url, name, image, date } = props;

  // 2018-06-31 -> Jun 31, 2018
  const formattedDate = date && format(parseISO(date), 'MMM d, Y');

  return (
    <AuthorAvatarBase url={url} name={name} image={image}>
      <span>on</span>
      <time dateTime={date}>{formattedDate}</time>
    </AuthorAvatarBase>
  );
}

AuthorAvatar.propTypes = {
  url: T.string,
  name: T.string,
  image: T.object,
  date: T.string
};

export function AuthorAvatarWithExpertise(props) {
  const { url, name, image, expertise } = props;

  return (
    <AuthorAvatarBase url={url} name={name} image={image}>
      <div className='expertise'>{expertise}</div>
    </AuthorAvatarBase>
  );
}

AuthorAvatarWithExpertise.propTypes = {
  url: T.string,
  name: T.string,
  image: T.object,
  expertise: T.string
};

const PostAuthorsList = styled.ul`
  ${listReset()}
  display: flex;

  li:not(:first-child) {
    margin-left: -0.5rem;
  }

  li {
    position: relative;
    z-index: 1;
    border-radius: ${themeVal('shape.rounded')};
    box-shadow: 0 0 0 4px #fff;
  }
`;

const PostMultiAuthors = styled(PostAuthor)`
  display: inline-grid;
  grid-gap: ${glsp(0, 1)};
  grid-auto-columns: auto 1fr;
  align-items: center;

  ${PostAuthorsList} {
    grid-row: 1 / span 2;
    grid-column: 1;
    color: ${themeVal('color.base')};
  }

  * {
    line-height: 1rem;
  }
`;

const ExtraAuthor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${_tint(0.8, themeVal('color.base-500'))};
  border-radius: ${themeVal('shape.rounded')};
  overflow: hidden;
  width: ${glsp(2.5)};
  height: ${glsp(2.5)};
  font-style: normal;
`;

// Multiple authors, non linked, truncated
export function AuthorsAvatarsTruncated(props) {
  const { authors, date } = props;

  // 2018-06-31 -> Jun 31, 2018
  const formattedDate = date && format(parseISO(date), 'MMM d, Y');

  const totalAuthors = authors.length;

  return (
    <PostMultiAuthors>
      <PostAuthorsList>
        {authors.slice(0, 3).map((a) => (
          <li key={a.slug}>
            <PostAuthorFigure>
              <GatsbyImg
                className='author-img'
                fixed={a.avatar}
                alt={`Avatar of ${a.name}`}
              />
            </PostAuthorFigure>
          </li>
        ))}
        {totalAuthors > 3 ? (
          <li>
            <ExtraAuthor>+{totalAuthors - 3}</ExtraAuthor>
          </li>
        ) : null}
      </PostAuthorsList>

      <strong>Multiple Authors</strong>
      <span>on</span>
      <time dateTime={date}>{formattedDate}</time>
    </PostMultiAuthors>
  );
}

AuthorsAvatarsTruncated.propTypes = {
  authors: T.arrayOf(
    T.shape({
      slug: T.string,
      name: T.string,
      avatar: T.object
    })
  ),
  date: T.string
};

const PostAuthorListItem = styled.li`
  transition: left 160ms;
  position: relative;
  left: 0;

  ${({ relToHover }) => {
    // What's the relation of this item to the hovered one?
    if (relToHover === 'before') {
      return css`
        left: -0.75rem;
      `;
    } else if (relToHover === 'after') {
      return css`
        left: 0.75rem;
      `;
    }
  }}
`;

// Multiple authors, linked, not truncated
function AuthorsAvatarsListBase(props) {
  const { authors, children } = props;
  const [hoverIdx, setHoverIdx] = useState();

  const left = authors.slice(0, -1);
  const last = authors[authors.length - 1];

  const getRelationToHovered = (idx) => {
    if (idx === hoverIdx - 1) {
      return 'before';
    } else if (idx === hoverIdx + 1) {
      return 'after';
    }
  };

  return (
    <PostMultiAuthors>
      <PostAuthorsList>
        {authors.map((a, idx) => (
          <PostAuthorListItem
            key={a.slug}
            relToHover={getRelationToHovered(idx)}
          >
            <Link
              to={`/team/${a.slug}`}
              onMouseEnter={() => setHoverIdx(idx)}
              onMouseLeave={() => setHoverIdx()}
            >
              <PostAuthorFigure>
                <GatsbyImg
                  className='author-img'
                  fixed={a.avatar}
                  alt={`Avatar of ${a.name}`}
                />
              </PostAuthorFigure>
            </Link>
          </PostAuthorListItem>
        ))}
      </PostAuthorsList>

      <div>
        {left.map((author, i) => (
          <React.Fragment key={author.slug}>
            <Link to={`/team/${author.slug}`}>{author.name}</Link>
            {i !== left.length - 1 ? ', ' : null}
          </React.Fragment>
        ))}{' '}
        & <Link to={`/team/${last.slug}`}>{last.name}</Link>
      </div>

      {children}
    </PostMultiAuthors>
  );
}

AuthorsAvatarsListBase.propTypes = {
  authors: T.arrayOf(
    T.shape({
      slug: T.string,
      name: T.string,
      avatar: T.object
    })
  ),
  children: T.node
};

export function AuthorsAvatarsList(props) {
  const { authors, date } = props;

  // 2018-06-31 -> Jun 31, 2018
  const formattedDate = date && format(parseISO(date), 'MMM d, Y');

  return (
    <AuthorsAvatarsListBase authors={authors}>
      <span>on</span>
      <time dateTime={date}>{formattedDate}</time>
    </AuthorsAvatarsListBase>
  );
}

AuthorsAvatarsList.propTypes = {
  authors: T.arrayOf(
    T.shape({
      slug: T.string,
      name: T.string,
      avatar: T.object
    })
  ),
  date: T.string
};

// Showing roles instead of the post date.
export function AuthorsAvatarsListWithExpertise(props) {
  const { authors } = props;

  const left = authors.slice(0, -1).map((a) => a.expertise);
  const last = authors[authors.length - 1];

  return (
    <AuthorsAvatarsListBase authors={authors}>
      <div className='expertise'>
        {left.join(', ')} & {last.expertise}
      </div>
    </AuthorsAvatarsListBase>
  );
}

AuthorsAvatarsListWithExpertise.propTypes = {
  authors: T.arrayOf(
    T.shape({
      slug: T.string,
      name: T.string,
      expertise: T.string,
      avatar: T.object
    })
  )
};
